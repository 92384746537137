import { ApolloClient, ApolloLink, HttpLink, HttpOptions, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { SentryLink } from 'apollo-link-sentry';
import * as Sentry from '@sentry/nextjs';

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      const error = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`;
      Sentry.captureMessage(error, 'error');
      console.error(error);
    });
  }

  if (networkError) {
    const error = `[Network error]: ${networkError}`;
    Sentry.captureMessage(error, 'error');
    console.error(error);
  }

  return forward(operation);
});

type Props = {
  token?: string | null;
};

export default function apollo({ token = null }: Props = {}) {
  const httpOptions: HttpOptions = {
    headers: {},
    uri: process.env.API_URL,
  };
  if (token) {
    httpOptions.headers.Authorization = `JWT ${token}`;
  }

  return new ApolloClient({
    link: ApolloLink.from([
      new SentryLink(),
      errorLink,
      new RetryLink(),
      new HttpLink(httpOptions),
    ]),
    cache: new InMemoryCache(),
  });
}
