import { ComponentStyleConfig } from '@chakra-ui/react';

const InputStyles: ComponentStyleConfig = {
  baseStyle: {
    field: {
      bg: 'white',
      _placeholder: {
        color: 'lightSteelBlue',
      },
      textAlign: 'center',
    },
  },
  sizes: {},
  variants: {
    main: {
      field: {
        border: '1px solid',
        borderColor: 'linkWater',
        borderRadius: '24px',
        fontFamily: 'poppins',
        fontSize: 's',
        lineHeight: 'm',
      },
    },
    square: {
      field: {
        border: '1px solid',
        borderColor: 'softBlue',
        borderRadius: '10px',
        boxShadow: '0px 5px rgba(0, 0, 0, 0.1)',
        fontFamily: 'poppins',
        fontSize: '19',
      },
    },
  },
  defaultProps: {
    variant: 'main',
  },
};

export default InputStyles;
