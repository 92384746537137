import Button from './components/buttonStyles';
import Input from './components/inputStyles';
import NumberInput from './components/numberInputStyles';
import PinInput from './components/pinInputStyles';
import Text from './components/textStyles';
import Textarea from './components/textareaStyles';

const myTheme = {
  colors: {
    aquaMarine: '#34bbd3',
    black: '#000',
    black0: 'rgba(0, 0, 0, 0)',
    black06: 'rgba(0, 0, 0, 0.06)',
    black10: 'rgba(0, 0, 0, 0.1)',
    black15: 'rgba(0, 0, 0, 0.15)',
    black30: 'rgba(0, 0, 0, 0.3)',
    black50: 'rgba(0, 0, 0, 0.5)',
    blueGrey: '#8e8e93',
    blueyGrey: '#8891ae',
    creamFrosting: '#fffef5',
    darkBlue: '#6D7389',
    darkGreyBlue: '#27465b',
    darkGreyBlueTwo: '#2e3757',
    darkGreyBlueTwo5: 'rgba(46, 55, 87, 0.05)',
    darkGreyBlueTwo10: 'rgba(46, 55, 87, 0.1)',
    darkGreyBlueTwo20: 'rgba(46, 55, 87, 0.2)',
    darkGreyBlueTwo30: 'rgba(46, 55, 87, 0.3)',
    darkGreyBlueTwo50: 'rgba(46, 55, 87, 0.5)',
    darkGreyBlueTwo70: 'rgba(46, 55, 87, 0.7)',
    darkGreyBlueTwo90: 'rgba(46, 55, 87, 0.9)',
    goldfish: '#e6e6e1',
    grayTundora: 'rgba(68, 68, 68, 1)',
    green: '#40c685',
    grey: '#969bab',
    grey010: '#f7f7f7',
    lightGrayTundora: 'rgba(103, 103, 103, 1)',
    lightGrey: '#c0c3cb',
    lightSteelBlue: '#b1b9ca',
    linkWater: '#dadce2',
    macaroniAndCheese: '#ffa41b',
    macaroniAndCheese20: 'rgba(255, 164, 27, 0.2)',
    offWhite: '#fffef6',
    offWhite50: 'rgba(255, 255, 253, 0.5)',
    offWhiteTwo: '#fffffd',
    offWhiteThree: '#fdfdfc',
    offWhiteFour: '#fffff8',
    offWhiteFive: '#fffffc',
    purple: '#767add',
    purple20: 'rgba(118, 122, 221, 0.2)',
    reddishBrown: '#890000',
    reddishOrange: '#fc634f',
    reddishOrange20: 'rgba(252, 99, 79, 0.2)',
    reddishOrangeDarken: '#e53900',
    seafoamBlue: '#40c685',
    seafoamBlue20: 'rgba(64, 198, 133, 0.2)',
    skyBlue: '#1ec6e4',
    softBlue: '#7c80ec',
    veryLightPink: '#f3f3f3',
    white: '#fff',
    white2: '#fafafa',
    white10: 'rgba(255, 255, 255, 0.1)',
  } as Record<string, string>,
  components: {
    Button,
    Input,
    NumberInput,
    PinInput,
    Text,
    Textarea,
  },
  fonts: {
    poppins: 'Poppins',
  },
  fontSizes: {
    xs: '.813rem',
    s: '.938rem',
    m: '1.063rem',
    xl: '2.125rem',
    19: '1.188rem',
    20: '1.25rem',
    25: '1.563rem',
    26: '1.625rem',
    35: '2.188rem',
    40: '2.5rem',
    42: '2.625rem',
    50: '3.125rem',
  },
  lineHeights: {
    m: 1.5,
    s: 1.25,
    xl: 2.5625,
    25: 1.5625,
    27: 1.6875,
    36: 2.25,
  },
};

export default myTheme;
