// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  environment: process.env.SENTRY_ENV || process.env.NODE_ENV,
  dsn: process.env.SENTRY_DSN,
  tracesSampleRate: Number.parseFloat(process.env.SENTRY_TRACES_RATE || '0'),
});
