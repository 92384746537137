import type { AppProps } from 'next/app';
import Head from 'next/head';

import * as React from 'react';
import { ApolloProvider } from '@apollo/client';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { useLocalStorage } from 'usehooks-ts';

import { AnalyticsProvider } from 'src/helpers/analytics';
import apollo from 'src/helpers/apollo';
import myTheme from 'src/theme';

import 'styles/globals.css';
import '@fontsource/poppins';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';

export default function MyApp({ Component, pageProps }: AppProps) {
  const [token] = useLocalStorage<string | null>('token', null);
  const apolloClient = React.useMemo(() => apollo({ token }), [token]);
  const theme = React.useMemo(() => extendTheme(myTheme), []);

  return (
    <AnalyticsProvider writeKey={process.env.SEGMENT_KEY || ''}>
      <ApolloProvider client={apolloClient}>
        <ChakraProvider theme={theme} resetCSS>
          <Head>
            <title>Birthday App</title>
            <link rel="icon" href="/images/favicon.ico" sizes="any" />
          </Head>
          <Component {...pageProps} />
        </ChakraProvider>
      </ApolloProvider>
    </AnalyticsProvider>
  );
}
