import { ComponentStyleConfig } from '@chakra-ui/react';

const PinInputStyles: ComponentStyleConfig = {
  baseStyle: {
    border: 'none',
  },
  sizes: {
    m: {
      borderRadius: '24px',
      h: '50px',
      w: '65px',
    },
  },
  variants: {
    grey: {
      bg: 'grey010',
    },
  },
  defaultProps: {
    size: 'm',
    variant: 'grey',
  },
};

export default PinInputStyles;
