import * as React from 'react';
import { AnalyticsBrowser } from '@segment/analytics-next';

export const AnalyticsContext = React.createContext<AnalyticsBrowser | null>(null);

type AnalyticsProviderProps = {
  writeKey: string;
  children: React.ReactNode;
};

export function AnalyticsProvider({ children, writeKey }: AnalyticsProviderProps) {
  const analytics = React.useMemo(() => AnalyticsBrowser.load({ writeKey }), [writeKey]);

  return <AnalyticsContext.Provider value={analytics}>{children}</AnalyticsContext.Provider>;
}
