import { ComponentStyleConfig } from '@chakra-ui/react';

const TextareaStyles: ComponentStyleConfig = {
  baseStyle: {
    _placeholder: {
      color: 'linkWater',
    },
    textAlign: 'center',
  },
  sizes: {},
  variants: {
    main: {
      border: 'none',
      borderRadius: '24px',
      fontFamily: 'poppins',
      fontSize: 'm',
      lineHeight: 'm',
      h: '173px',
    },
  },
  defaultProps: {
    variant: 'main',
  },
};

export default TextareaStyles;
