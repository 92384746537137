import { ComponentStyleConfig } from '@chakra-ui/react';

const ButtonStyles: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: '100px',
    boxShadow: '0px 5px rgba(0, 0, 0, 0.1)',
    color: 'white',
    _disabled: {
      opacity: '10%',
    },
    p: '12px 20px',
    w: '100%',
  },
  sizes: {},
  variants: {
    blue: {
      bg: 'darkGreyBlueTwo',
    },
    ghost: {
      bg: 'none',
      boxShadow: 'none',
      color: 'lightSteelBlue',
      fontSize: 'xs',
      fontWeight: 'medium',
    },
    green: {
      bg: 'green',
    },
    lightBlue: {
      bg: 'none',
      border: '2px solid',
      borderColor: 'softBlue',
      boxShadow: 'none',
      color: 'softBlue',
      fontSize: 's',
      fontWeight: 'semibold',
    },
    red: {
      bg: 'reddishOrange',
    },
  },
  defaultProps: {
    variant: 'blue',
  },
};

export default ButtonStyles;
