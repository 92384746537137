import { ComponentStyleConfig } from '@chakra-ui/react';

const TextStyles: ComponentStyleConfig = {
  baseStyle: {
    color: 'darkGreyBlueTwo',
    fontFamily: 'poppins',
  },
  sizes: {
    m: {
      fontSize: 'm',
      lineHeight: 'm',
    },
    xl: {
      fontSize: 'xl',
      fontWeight: 'semibold',
      lineHeight: 'xl',
    },
    xs: {
      fontSize: 'xs',
      lineHeight: 's',
    },
  },
  variants: {
    light: {
      color: 'darkBlue',
    },
    lightest: {
      color: 'lightGrey',
    },
  },
  defaultProps: {},
};

export default TextStyles;
